<script setup lang="ts">
import type { UiNotificationType } from "../composables/useNotifications";
import UiIcon from "./UiIcon.vue";

const props = defineProps<{
  type: UiNotificationType;
  message?: string;
  closeable?: boolean;
}>();

const emit = defineEmits<{
  (e: "click"): void;
  (e: "close"): void;
}>();

const onClick = () => {
  emit("click");

  if (props.closeable) {
    emit("close");
  }
};
</script>

<template>
  <div class="ui-notification" :class="type" @click.stop="onClick()">
    <div class="ui-notification__message">
      <slot>{{ message }}</slot>
    </div>

    <!--<button
      type="button"
      class="ui-notification__button"
      :title="$t('Close')"
      tabindex="0"
      @click="$emit('close')"
      v-if="closeable"
    >
      <UiIcon name="close" :size="20" />
    </button>-->
  </div>
</template>

<style scoped>
.ui-notification {
  @apply flex flex-row;
  @apply bg-black text-white;
  @apply select-none;
}
.ui-notification.error {
  @apply bg-red-600 text-white;
}
.ui-notification.success {
  @apply bg-green-600 text-white;
}

.ui-notification__message {
  @apply px-3 py-2;
}

.ui-notification__button {
  @apply px-2 -ml-2;
}
</style>
