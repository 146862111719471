import type { App } from "vue";
import { UiContextKey } from "./injectables";

export interface UiSettings {
  translate: (message: string) => string | undefined;
}

export const defaultContext: UiSettings = {
  translate: (message) => message,
};

export const install = (app: App, config: Partial<UiSettings> = {}) => {
  const settings: UiSettings = Object.assign({}, defaultContext, config);

  app.provide(UiContextKey, settings);
};
