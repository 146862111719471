<script setup lang="ts">
import { ref, watch } from "vue";
import { useClamp } from "@vueuse/math";
import { useNotifications } from "../composables/useNotifications";
import UiNotification from "./UiNotification.vue";

const { notifications, removeNotification } = useNotifications();

const isActive = ref(false);
const visibleCount = useClamp(0, 0, Infinity);

const onBeforeEnter = () => visibleCount.value++;
const onAfterLeave = () => visibleCount.value--;

watch(visibleCount, (count) => (isActive.value = count > 0));

watch(notifications, (items) => {
  if (items.length > 0) {
    isActive.value = true;
  }
});
</script>

<template>
  <div class="ui-notification-stack" v-if="isActive">
    <div class="ui-notification-stack__messages">
      <TransitionGroup
        name="list"
        appear
        @before-enter="onBeforeEnter()"
        @after-leave="onAfterLeave()"
      >
        <UiNotification
          v-for="notification in notifications"
          :key="`${notification.type}:${notification.message}`"
          @close="removeNotification(notification)"
          closeable
          v-bind="notification"
        />
      </TransitionGroup>
    </div>
  </div>
</template>

<style scoped>
.ui-notification-stack {
  @apply fixed top-0 left-0 right-0 z-50;
  @apply pointer-events-none;
}

.ui-notification-stack__messages {
  @apply flex flex-col items-center;
  @apply max-w-md;
  @apply mx-auto px-4 py-6 space-y-2;
}
.ui-notification-stack__messages:deep(*) {
  @apply pointer-events-auto;
}

.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
  will-change: opacity, transform;
}
.list-leave-active {
  transition-duration: 0.35s;
}
.list-enter-from {
  transform: translateY(-200%);
  opacity: 0;
}
.list-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
</style>
