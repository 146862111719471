import { install } from "./plugin";

import UiAvatar from "./components/UiAvatar.vue";
import UiButton from "./components/UiButton.vue";
import UiCard from "./components/UiCard.vue";
import UiIcon from "./components/UiIcon.vue";
import UiInput from "./components/UiInput.vue";
import UiLabel from "./components/UiLabel.vue";
import UiNotification from "./components/UiNotification.vue";
import UiNotificationStack from "./components/UiNotificationStack.vue";
import UiPasswordField from "./components/UiPasswordField.vue";
import UiPopover from "./components/UiPopover.vue";
import UiPopoverButton from "./components/UiPopoverButton.vue";
import UiPopoverPanel from "./components/UiPopoverPanel.vue";
import UiSelect from "./components/UiSelect.vue";
import UiSpinner from "./components/UiSpinner.vue";
import UiStrip from "./components/UiStrip.vue";

export type { UiIconName } from "./components/UiIcon.vue";

export { useNotifications } from "./composables/useNotifications";

export {
  UiAvatar,
  UiButton,
  UiCard,
  UiIcon,
  UiInput,
  UiLabel,
  UiNotification,
  UiNotificationStack,
  UiPasswordField,
  UiPopover,
  UiPopoverButton,
  UiPopoverPanel,
  UiSelect,
  UiSpinner,
  UiStrip,
};

export default { install };
