import type { DefineComponent } from "vue";

export async function resolvePageComponent<T>(
  path: string,
  pages: Record<string, Promise<T> | (() => Promise<T>)>
): Promise<T> {
  const page = pages[path];

  if (typeof page === "undefined") {
    throw new Error(`Page not found: ${path}`);
  }

  return typeof page === "function" ? page() : page;
}

export async function resolvePage(name: string): Promise<DefineComponent> {
  const component = await resolvePageComponent<DefineComponent>(
    `./pages/${name}.vue`,
    import.meta.glob<DefineComponent>("./pages/**/*.vue")
  );

  if (component === undefined) {
    throw new Error(`Page not found: ${name}`);
  }

  return component.default;
}

export async function withDefaultLayout(
  resolveComponent: Promise<DefineComponent>,
  resolveLayout: Promise<typeof import("*.vue")>
): Promise<DefineComponent> {
  const component = await resolveComponent;

  if (component && component.layout === undefined) {
    component.layout = (await resolveLayout).default;
  }

  return component;
}
