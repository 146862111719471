import { computed, createApp, h } from "vue";
import { createInertiaApp, usePage } from "@inertiajs/vue3";
import { resolvePage, withDefaultLayout } from "./inertia";
import { createLocalization } from "./plugins/localization";
import { routes } from "./plugins/routes";
import ui, { useNotifications } from "./ui";
import Root from "./App.vue";
import "../styles/app.css";

createInertiaApp({
  progress: {
    color: "#3b82f6",
  },
  resolve: (name) =>
    withDefaultLayout(resolvePage(name), import("./layouts/AppLayout.vue")),
  setup({ App, el, props, plugin }) {
    const initial = props.initialPage.props;

    const localization = createLocalization({
      locale: initial.app.locale || "en",
      fallbackLocale: initial.app.fallbackLocale || "en",
      url: ({ locale }) => `${initial.app.base}locale/messages/${locale}`,
    });

    createApp({ render: () => h(Root, () => h(App, props)) })
      .use(plugin)
      .use(routes)
      .use(localization)
      .use(ui, {
        translate: localization.translate,
      })
      .mount(el);

    // Use page locale for localization.
    const locale = computed(() => usePage().props.app.locale);
    localization.syncLocale(locale);

    // Add page messages to notification stack.
    const { watchNotifications } = useNotifications();
    const messages = computed(() => usePage().props.session.flashMessages);
    watchNotifications(messages, { lifetime: 5000 });
  },
});
