/**
 * Convert a string to UPPERCASE.
 */
export function uppercase(value: string) {
  return value.toUpperCase();
}

/**
 * Convert a string to Capitalized casing.
 */
export function capitalize(value: string) {
  return uppercase(value[0]) + value.slice(1);
}
