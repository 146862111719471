import { App, InjectionKey } from "vue";
import route, {
  Config,
  RouteParam,
  RouteParamsWithQueryOverload,
  Router,
} from "ziggy-js";

type RouteFunction = (
  name: string,
  params?: RouteParamsWithQueryOverload | RouteParam,
  absolute?: boolean,
  config?: Config
) => string;

interface RouteProvider {
  router: Router;
  route: RouteFunction;
}

declare module "@vue/runtime-core" {
  export interface ComponentCustomProperties {
    $router: Router;
    $route: RouteFunction;
  }
}

export const RoutesInjectionKey = Symbol(
  "routes"
) as InjectionKey<RouteProvider>;

export const routes = {
  install(app: App, options?: Config) {
    const $router: Router = route();

    const $route: RouteFunction = (
      name,
      params?,
      absolute?,
      config = options
    ) => route(name, params, absolute, config);

    app.config.globalProperties.$router = $router;
    app.config.globalProperties.$route = $route;

    app.provide(RoutesInjectionKey, {
      route: $route,
      router: $router,
    });
  },
};
