import {
  App,
  Ref,
  readonly,
  ref,
  unref,
  watch,
  InjectionKey,
  inject,
} from "vue";
import {
  asyncComputed,
  MaybeRef,
  set,
  until,
  useMemoize,
  whenever,
} from "@vueuse/core";
import { capitalize, uppercase } from "@/utils/text";

interface LocalizationConfig {
  readonly locale: string;
  readonly fallbackLocale: string;
  readonly url: (context: LocalizationUrlContext) => string;
}

interface LocalizationUrlContext {
  locale: string;
}

type LocalizationLocale = string;
type LocalizationKey = string;
type LocalizationCountable = number | ArrayLike<any>;
type LocalizationReplacements = Record<string, any>;
type LocalizationMessage = string;
type LocalizationMessages = Record<LocalizationKey, LocalizationMessage>;

declare module "@vue/runtime-core" {
  export interface ComponentCustomProperties {
    $locale: Readonly<Ref<LocalizationLocale>>;
    $t: (
      key: LocalizationKey,
      replace?: LocalizationReplacements
    ) => LocalizationMessage;
    $tc: (
      key: LocalizationKey,
      countable: LocalizationCountable,
      replace?: LocalizationReplacements
    ) => LocalizationMessage | undefined;
  }
}

export const LocalizationInjectionKey = Symbol("localization") as InjectionKey<{
  locale: Readonly<Ref<string>>;
  setLocale: (locale: string) => void;
  translate: (key: string, replace?: LocalizationReplacements) => string;
  translateChoice: (
    key: string,
    countable: LocalizationCountable,
    replace?: LocalizationReplacements
  ) => string | undefined;
  t: (key: string, replace?: LocalizationReplacements) => string;
  tc: (
    key: string,
    countable: LocalizationCountable,
    replace?: LocalizationReplacements
  ) => string | undefined;
}>;

export const useLocalization = () => {
  const localization = inject(LocalizationInjectionKey);

  if (localization === undefined) {
    throw new Error("Could not inject localization provider");
  }

  return localization;
};

function makeReplacement(target: string, key: string, value: any) {
  value = String(value);
  target = target.replaceAll(":" + key, value);
  target = target.replaceAll(":" + capitalize(key), capitalize(value));
  target = target.replaceAll(":" + uppercase(key), uppercase(value));
  return target;
}

function extract(segments: string[], count: number) {
  for (const segment in segments) {
    const line = extractFromString(segments[segment], count);
    if (line) return line;
  }
}

function extractFromString(part: string, count: number) {
  const matches = part.match(/^[\{\[]([^\[\]\{\}]*)[\}\]](.*)/s);

  if (matches === null || matches.length !== 3) {
    return undefined;
  }

  const condition = matches[1];
  const value = matches[2];

  if (condition.indexOf(",") !== -1) {
    const [from, to] = condition.split(",", 2);
    const fromInt = parseInt(from);
    const toInt = parseInt(to);

    if (to === "*" && count >= fromInt) {
      return value;
    } else if (from === "*" && count <= toInt) {
      return value;
    } else if (count >= fromInt && count <= toInt) {
      return value;
    }
  }

  return parseInt(condition) === count ? value : undefined;
}

function getPluralIndex(count: number) {
  return count === 1 ? 0 : 1;
}

function createMessageLoader(
  locale: Ref<LocalizationLocale>,
  cb: (locale: LocalizationLocale) => Promise<LocalizationMessages>
) {
  return asyncComputed<LocalizationMessages>(() => cb(locale.value), {});
}

async function fetchMessages(
  urlFn: (ctx: LocalizationUrlContext) => string,
  locale: LocalizationLocale
): Promise<LocalizationMessages> {
  try {
    const url = urlFn({ locale });
    const res = await fetch(url);
    return await res.json();
  } catch (err) {
    return {};
  }
}

export function createLocalization(config: LocalizationConfig) {
  const currentLocale = ref<LocalizationLocale>(config.locale);
  const fallbackLocale = ref<LocalizationLocale>(config.fallbackLocale);

  const getMessages = useMemoize(
    async (locale: LocalizationLocale) =>
      await fetchMessages(config.url, locale)
  );

  const messages = createMessageLoader(currentLocale, getMessages);
  const fallbackMessages = createMessageLoader(fallbackLocale, getMessages);

  const isReady = until(messages).toMatch((m) => Object.keys(m).length > 0);

  const getMessage = (key: LocalizationKey) =>
    messages.value[key] || fallbackMessages.value[key] || key;

  const setLocale = (locale: LocalizationLocale) => set(currentLocale, locale);

  const setFallbackLocale = (locale: LocalizationLocale) =>
    set(fallbackLocale, locale);

  const syncLocale = (locale: Ref<LocalizationLocale>) =>
    whenever(locale, (locale) => setLocale(locale), { immediate: true });

  const translate = (
    key: LocalizationKey,
    replace: MaybeRef<LocalizationReplacements> = {}
  ): LocalizationMessage => {
    const rawReplace = unref(replace);

    let message = getMessage(key);

    for (const key in rawReplace) {
      message = makeReplacement(message, key, rawReplace[key]);
    }

    return message;
  };

  const translateChoice = (
    key: LocalizationKey,
    countable: MaybeRef<LocalizationCountable>,
    replace: MaybeRef<LocalizationReplacements> = {}
  ): LocalizationMessage | undefined => {
    countable = unref(countable);

    const count =
      typeof countable === "number" ? countable : Array.from(countable).length;

    const replaceWithCount = Object.assign({ count }, replace);
    const pluralIndex = getPluralIndex(count);

    const message = translate(key, replaceWithCount);
    const segments = message.split("|").map((message) => message.trim());

    const value = extract(segments, count);

    if (value) {
      return value;
    }

    if (segments.length === 1 || !segments[pluralIndex]) {
      return segments[0];
    }

    return segments[pluralIndex];
  };

  const install = (app: App) => {
    app.config.globalProperties.$locale = readonly(currentLocale);
    app.config.globalProperties.$t = translate;
    app.config.globalProperties.$tc = translateChoice;

    app.provide(LocalizationInjectionKey, {
      locale: currentLocale,
      setLocale,
      translate,
      translateChoice,
      t: translate,
      tc: translateChoice,
    });
  };

  watch(
    currentLocale,
    (locale) => {
      document?.documentElement?.setAttribute("lang", locale);
    },
    { immediate: true }
  );

  return {
    isReady: () => isReady,
    locale: () => unref(currentLocale),
    setLocale,
    setFallbackLocale,
    syncLocale,
    translate,
    translateChoice,
    install,
  };
}
