import { Ref, readonly, watch, reactive } from "vue";
import { createSharedComposable } from "@vueuse/core";

export type UiNotificationType = "info" | "error" | "success";

export interface UiNotificationOptions {
  readonly delay?: number;
  readonly lifetime?: number;
}

export interface UiNotification {
  readonly type: UiNotificationType;
  readonly message: string;
}

export const useNotifications = createSharedComposable(() => {
  const notifications = reactive<UiNotification[]>([]);

  const pushNotification = (
    type: UiNotificationType,
    message: string,
    options: UiNotificationOptions = {}
  ) => {
    const delay = options?.delay || 0;
    const lifetime = options?.lifetime || 0;
    const notification: UiNotification = { type, message };

    const remove = () => removeNotification(notification);

    const push = () => {
      notifications.unshift(notification);

      if (lifetime > 0) {
        setTimeout(remove, lifetime);
      }
    };

    const pushTimeout = setTimeout(push, delay);

    const cancel = () => clearTimeout(pushTimeout);

    return { notification, cancel, remove };
  };

  const removeNotification = (notification: UiNotification) => {
    const index = notifications.indexOf(notification);

    if (index !== -1) {
      notifications.splice(index, 1);
    }
  };

  const clearNotifications = () =>
    notifications.splice(0, notifications.length);

  const watchNotifications = (
    source: Ref<UiNotification[]>,
    options?: UiNotificationOptions
  ) =>
    watch(
      source,
      (items) =>
        items.forEach(({ type, message }) =>
          pushNotification(type, message, options)
        ),
      { immediate: true }
    );

  return {
    notifications: readonly(notifications),
    pushNotification,
    removeNotification,
    clearNotifications,
    watchNotifications,
  };
});
